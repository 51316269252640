.Spinner,
.Spinner:before,
.Spinner:after {
  background: #000000;
  animation: load1 1s infinite ease-in-out;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  width: 1em;
  height: 4em;
}
.Spinner {
  color: #000000;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.Spinner:before,
.Spinner:after {
  position: absolute;
  top: 0;
  content: '';
}
.Spinner:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.Spinner:after {
  left: 1.5em;
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 -0.4em;
    height: 0.2em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 0.2em;
  }
}
